import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Tutorial from "../components/joyride";
import DistributedCircle from "../components/circle";
import PageHeading from "../components/page_heading";
import { Auth } from "aws-amplify";
import SectionsData from "../../content/sections.json";

const IndexPage = ({ data }) => {
  const steps = SectionsData.steps;
  const headingText = "Fromage & Programming!";
  const subHeadingText =
    "A Website where you will find tips and stories about Programming. And Cheese.";

  return (
    <div>
      <Layout doNavBarHovering heroSrc={undefined} iconName="fromprog_anim">
        <Tutorial steps={steps} name={"home-joyride"}/>
        <PageHeading
          headingText={headingText}
          subHeadingText={subHeadingText}
        />
        {/* <GridNumber /> */}
        <DistributedCircle steps={steps.slice(1)}/>
        {/* {dummyFiller} */}
      </Layout>
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        social {
          linkedin
          facebook
        }
      }
    }
  }
`;
