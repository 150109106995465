import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { HoverContext } from "./circle_context";
import { getIcon } from "./icon";

const CycleGraphWrapper = styled.div`
  // necessary for absolutely positioned center
  position: relative;
`;

function CircleEl({ sectionName, href, className }) {
  const { hoverState, setHoverState } = useContext(HoverContext);

  function updateMenu() {
    setHoverState((prevState) => {
      // Use computed properties and spread operator
      return sectionName;
    });
  }

  function resetMenu() {
    setHoverState((prevState) => {
      // Use computed properties and spread operator
      return null;
    });
  }
  const iconClassNames = `circle-icon ${sectionName}-icon`;
  let child = getIcon(sectionName, iconClassNames);

  return (
    <div
      className={`${className} ${hoverState == sectionName && "circle_hover"}`}
      onMouseEnter={updateMenu}
      onMouseLeave={resetMenu}
    >
      <a href={href}>{child}</a>
    </div>
  );
}

const StyledCircleEl = styled(CircleEl)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 6.25em;
  aspect-ratio: 1/1;

  width: 35%;
  margin: max(-3.125em, calc(-35% /2));

  border-radius: 50%;
  transition: 0.5s;
  color: ${({ theme }) => theme.maincolor.color2};
  background-color: ${({ theme }) => theme.maincolor.almost_white};

  /* &.circle_hover { */
  &:hover {
    background-color: ${({ theme }) => theme.maincolor.color2_diluted};
  }
  .circle-icon {
    transition: 0.5s;
    color: ${({ theme }) => theme.maincolor.color2};
    position: absolute;
    top: 50%;
    left: 50%;
    /* Diameter of an indvidual item */
    width: 80%;
    height: 80%;
    margin: -40%;
    color: ${({ theme }) => theme.maincolor.color1};
  }
`;

function arrayRotate(arr, reverse) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
}

const CycleGraph = styled.div`
  position: relative;
  /* Size of the circle on which items are set */
  max-width: 18.75em;
  width: 50%;
  aspect-ratio: 1 / 1;
  margin: auto;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid teal;
    width: calc(100% - 2px * 2);
    height: calc(100% - 2px * 2);
    border-radius: 50%;
  }
`;

const CircleCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

function DistributedCircle({ steps }) {
  const graph = useRef(null);
  steps = arrayRotate(steps);

  useEffect(() => {
    const ciclegraph = graph.current;
    const circleElements = ciclegraph.childNodes;

    let angle = 360 - 90;
    let dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 2
      }px) rotate(-${angle}deg)`;
    }
  }, []);
  const circleElements = steps.map((step, index) => (
    <StyledCircleEl
      sectionName={step.section}
      href={`/${step.section}/`}
      key={step.section}
    />
  ));

  return (
    <CycleGraphWrapper>
      <CircleCenter className="joyride-start" />
      <CycleGraph ref={graph}>{circleElements}</CycleGraph>
    </CycleGraphWrapper>
  );
}

export default DistributedCircle;
